<template>
	<div class="root">
		<el-card class="msg-card">
			<div slot="header" class="clearfix">
				<span>服务记录</span>
				<el-button
					style="float: right; padding: 3px 0"
					type="text"
					@click="selectCertificate"
					>查看荣誉证书</el-button
				>
			</div>
			<el-table :data="volunteerInfo">
				<el-table-column
					prop="volunteerName"
					label="姓名"
				></el-table-column>
				<el-table-column
					prop="deptName"
					label="服务场景"
				></el-table-column>
				<el-table-column
					prop="volunteerWorkingHours"
					label="服务时长"
				></el-table-column>
				<el-table-column
					prop="workingStartTime"
					label="服务开始时间"
				></el-table-column>
				<el-table-column
					prop="workingEndTime"
					label="服务结束时间"
				></el-table-column>
			</el-table>
			<div v-if="total > size" class="pages">
				<el-pagination
					@current-change="currentChange"
					background
					layout="prev, pager, next"
					:total="total"
					:page-size="size"
					:current-page="page"
				>
				</el-pagination>
			</div>
		</el-card>
		<el-dialog
			:visible.sync="dialogVisible"
			width="550px"
		>
			<VolunteerCertificate></VolunteerCertificate>
		</el-dialog>
	</div>
</template>

<script>
import { volunteerServeList_api } from '@/api/user'
import VolunteerCertificate from '@/views/volunteer/VolunteerCertificate.vue'
import { sessionGet } from '@/utils/local.js'
export default {
	data() {
		return {
			volunteerInfo: [], //志愿者信息
			dialogVisible: false,
			current: 1,
			page: 1, // 当前页码
			size: 10, // 每页条数
			total: 0, // 总条数
		}
	},
	components: {
		VolunteerCertificate,
	},
	methods: {
		async getList() {
			let { data, code, msg } = await volunteerServeList_api({
				size: this.size,
				current: this.current,
				docId: sessionGet('userInfo').doc_id,
			})
			if (code == 200) {
				this.total = data.total
				console.log(data)
				this.volunteerInfo = data.records
			}
		},
		// 页码改变
		currentChange(page) {
			this.page = page
			this.getList()
		},
		//查看荣誉证书
		selectCertificate() {
			this.dialogVisible = true
		},
	},
	mounted() {
		this.getList()
		console.log(sessionGet('userInfo'))
	},
}
</script>

<style lang="less" scoped>
.root {
	.msg-card {
		.pages {
			display: flex;
			justify-content: flex-end;
			.el-pagination {
				margin-top: 20px;
			}
		}
	}
	.el-dialog__wrapper {
		/deep/.el-dialog {
            margin-top: 20px !important;
			.el-dialog__body {
				padding: 0;
			}
			.el-dialog__header {
				display: none;
			}
		}
	}
}
</style>
