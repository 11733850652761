<template>
	<div class="root">
		<div
			class="certificate canvas_box"
			id="mycanvas"
			ref="mycanvas"
			v-if="volunteerData"
		>
			<div class="certificate-border">
				<el-image :src="bgUrl" class="certificate-bg"></el-image>
				<el-image :src="logoUrl" class="certificate-title"></el-image>
				<el-image
					:src="centerUrl"
					class="certificate-center"
				></el-image>
				<!-- <span class="num">NO.{{ this.volunteerData.id }}</span> -->
				<span class="num1"></span>
				<span class="title1">CERTIFICATE</span>
				<span class="title2">voluntary service</span>
				<div class="container">
					<p class="container-one">
						<span class="volunteerName">{{
							this.volunteerData.volunteerName
						}}</span
						>同学/同志：
					</p>
					<p class="container-two" style="textIndent: 2em">
						于<span>{{ this.startYear }}</span
						>年<span>{{ this.startMonth }}</span
						>月<span>{{ this.startDay }}</span
						>日<span class="endDay"
							>至<span>{{ this.endYear }}</span
							>年<span>{{ this.endMonth }}</span
							>月<span>{{ this.endDay }}</span
							>日</span
						>参加<!-- <span>北京第二外国语学院成都附属小学</span>的 -->武侯乐学通志愿者服务，累计服务时长<span
							>{{ parseInt(this.volunteerData.countHours) }}</span
						>小时。活动期间态度积极友好，表现优异！
					</p>
					<p class="container-three" style="textIndent: 2em">
						特颁此证，以资证明
					</p>
				</div>
				<div class="sign">
					<p>武侯乐学通</p>
					<p>
						{{ this.endYear }}年{{ this.endMonth }}月{{
							this.endDay
						}}日
					</p>
				</div>
				<div class="id">
					<span class="num">NO.{{ this.volunteerData.id }}</span>
				</div>
			</div>
		</div>
		<div class="download">
			<el-button type="primary" @click="saveImg('mycanvas')"
				>下载证书</el-button
			>
		</div>
	</div>
</template>

<script>
import html2canvas from 'html2canvas'
import certificateBg from '@/assets/img/certificateBg.png'
import logo from '@/assets/img/logo2.png'
import certificateCenter from '@/assets/img/certificateCenter.png'
import { volunteerWorkingBillCount_api } from '@/api/user'
import { sessionGet } from '@/utils/local.js'
export default {
	data() {
		return {
			bgUrl: certificateBg,
			logoUrl: logo,
			centerUrl: certificateCenter,
			volunteerData: null,
		}
	},
	computed: {
		startYear() {
			return new Date(this.volunteerData.workingStartTime).getFullYear()
		},
		startMonth() {
			return new Date(this.volunteerData.workingStartTime).getMonth() + 1
		},
		startDay() {
			return new Date(this.volunteerData.workingStartTime).getDate()
		},
		endYear() {
			return new Date(this.volunteerData.workingEndTime).getFullYear()
		},
		endMonth() {
			return new Date(this.volunteerData.workingEndTime).getMonth() + 1
		},
		endDay() {
			return new Date(this.volunteerData.workingEndTime).getDate()
		},
	},
	methods: {
		saveImg(val) {
			window.pageYoffset = 0
			document.documentElement.scrollTop = 0
			document.body.scrollTop = 0
			// 先获取你要转换为img的dom节点
			var node = document.getElementById(val) //传入的id名称
			// console.log("node", node);
			var width = node.offsetWidth //dom宽
			var height = node.offsetHeight //dom高
			var scale = 2 //放大倍数 这个相当于清晰度 调大一点更清晰一点
			html2canvas(node, {
				width: width,
				heigth: height,
				backgroundColor: '#ffffff', //背景颜色 为null是透明
				dpi: window.devicePixelRatio * 2, //按屏幕像素比增加像素
				scale: scale,
				X: 0,
				Y: 0,
				scrollX: -3, //如果左边多个白边 设置该偏移-3 或者更多
				scrollY: 0,
				useCORS: true, //是否使用CORS从服务器加载图像 !!!
				allowTaint: true, //是否允许跨域图像污染画布  !!!
			}).then((canvas) => {
				// console.log("canvas", canvas);
				var url = canvas.toDataURL() //这里上面不设值cors会报错
				var a = document.createElement('a') //创建一个a标签 用来下载
				a.download = '志愿者荣誉证书' //设置下载的图片名称
				var event = new MouseEvent('click') //增加一个点击事件
				a.href = url //此处的url为base64格式的图片资源
				a.dispatchEvent(event) //触发a的单击事件 即可完成下载
			})
		},
		async getVolunteerInfo() {
			let { code, data, msg } = await volunteerWorkingBillCount_api({
				docId: sessionGet('userInfo').doc_id,
			})
			if (code == 200) {
				this.volunteerData = data
			} else {
				this.$message.error(msg)
			}
		},
	},
	mounted() {
		this.getVolunteerInfo()
	},
}
</script>

<style lang="less" scoped>
.root {
	.certificate {
		width: 500px;
		margin: 0 auto;
		padding: 30px;
		.certificate-border {
			position: relative;
			padding: 5px;
			border: 15px solid black;
			.certificate-bg {
			}
			.certificate-title {
				width: 150px;
				position: absolute;
				top: 65px;
				left: calc(250px - 90px);
			}
			.certificate-center {
				position: absolute;
				top: 0;
				left: 0;
			}

			.num1 {
				background-color: #fff;
				position: absolute;
				top: 66px;
				right: 8%;
				height: 15px;
				width: 90px;
			}
			.title1 {
				position: absolute;
				top: 170px;
				left: calc(250px - 101px);
				font-family: Times New Roman;
				font-weight: bolder;
				font-size: 2em;
			}
			.title2 {
				position: absolute;
				top: 240px;
				left: 50%;
				margin-left: -73px;
				color: white;
				font-size: medium;
			}
			.container {
				position: absolute;
				top: 280px;
				left: 15%;
				width: 70%;
				margin: 0 auto;
				p {
					line-height: 30px;
					span {
						text-decoration: underline;
					}
					.endDay {
						text-decoration: none;
					}
				}
				.container-one {
					margin-top: 10px;
					margin-bottom: 10px;
					font-size: 20px;
					font-weight: bold;
					.volunteerName {
						text-decoration: none;
						font-family: KaiTi;
						font-weight: bold;
						padding: 0 14px;
						border-bottom: 2px solid rgb(107, 107, 107);
					}
				}
				.container-two {
					font-weight: 500;
					font-size: 15px;
				}
				.container-three {
					font-weight: bold;
					font-size: 15px;
				}
			}
			.sign {
				position: absolute;
				bottom: 17%;
				right: 15%;
				text-align: center;
				p {
					line-height: 25px;
					font-weight: 500;
					font-size: 15px;
				}
			}
			.id {
				width: 100%;
				position: absolute;
				bottom: 5%;
				text-align: center;
				.num {
					font-size: 10px;
					color: rgb(226, 92, 3);
					// position: absolute;
					// bottom: 40px;
					// right: 0;
				}
			}
		}
	}
	.download {
		display: flex;
		justify-content: center;
		padding-bottom: 20px;
	}
}
</style>
